@import "./assets/style/app.scss";
// @import './core//styles/_variables.scss';
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/gotham-6");

:root {
  --primaryColor: #a3db59;
  --primaryColorDark: #7d9943;
  --formElementRadius: 16px;
  --formElementSpacing: 20px;
  --formFontFamily: "Montserrat";
  --formFontSize: 16px;
  --formFontColor: #000000;
  --formPageOpacity: 1;
  --formFormWidth: 600;
  --formSubmitButtonFontColor: #000000;
  --formSubmitButtonColor: var(--primaryColor);
  --formPageBackgroundColor: grey;
}

body,
html {
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  height: 100%;
  // font-family: 'Montserrat', sans-serif;
}

body {
  height: 100%;
  background-color: transparent !important;
}

.grecaptcha-badge {
  z-index: 999;
}

* {
  box-sizing: border-box;
}

img {
  display: inline-block;
}

a {
  color: var(--primaryColor);

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.ui-loader {
  .loader {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
      color: var(--primaryColor) !important;
      width: 50px;
      height: 50px;
    }
  }
}

// custom-react-select
.custom-select__control {
  border: 1px solid #e8e8e8 !important;
  background-color: #ffffff !important;
  // border-radius: 10px;
  // min-height: 49px !important;
  min-height: 41.2px !important;
  border-radius: 7px !important;
  height: auto;
  // padding-left: 15px;
  padding-left: 12px;
  width: 100% !important;

  .custom-select__placeholder {
    color: #b9b9c3;
    font-size: 16px;
    margin: 0px;
  }

  .custom-select__single-value {
    margin: 0px;
    font-size: 14px;
    color: #6e6b7b;
    // font-family: 'Montserrat', sans-serif;
  }

  .custom-select__value-container {
    padding: 0px;
  }

  .custom-select__value-container--is-multi {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .custom-select__input-container {
    padding: 0px;
    margin: 0px;
  }

  .custom-select__indicator-separator {
    display: none;
  }

  .custom-select__indicator {
    color: grey;
    padding: 0px;
  }
}

.custom-select__control.custom-select__control--is-focused {
  box-shadow: unset;
  border: 1px solid var(--primaryColor);
}

.custom-select__menu-portal {
  z-index: 4;
}

.custom-select__menu {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-top: 2px;

  .custom-select__option {
    font-size: 15px;
    color: grey;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-select__option.custom-select__option--is-focused {
    background-color: #f2f2f2;
    color: var(--primaryColorDark) !important;
  }

  .custom-select__option.custom-select__option--is-selected {
    background-color: var(--primaryColor) !important;
    color: #ffffff !important;
  }
}

.custom-select__clear-indicator {
  padding: 0px 0px;
  margin-right: 2px;
}

.custom-select__dropdown-indicator {
  margin-right: 7px;
}

.custom_select_wrapper {
  .cs_form_label {
    color: var(--primaryColor);
    font-size: 14px;
    left: 11px;
    top: -9px;
    background-color: white;
    padding: 0px 5px;
    transform: scale(1);
    z-index: 0;

    &.focus-out {
      top: 13px;
      pointer-events: none;
      opacity: 0;
      transition: unset !important;
    }
  }
}

// custom-react-select end

// site-loader
.site-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 99999;

  .inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100px;
    }
  }
}

.site-loader .first {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 1050;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-first;
  animation-timing-function: linear;
}

@keyframes site-loader-first {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 1050;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 2100;
    fill: var(--primaryColor);
  }

  to {
    stroke-dashoffset: 2100;
    fill: var(--primaryColor);
  }
}

.site-loader .second {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 742;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-second;
  animation-timing-function: linear;
}

@keyframes site-loader-second {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 742;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 1484;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 1484;
    fill: var(--primaryColor);
  }

  to {
    stroke-dashoffset: 1484;
    fill: var(--primaryColor);
  }
}

.site-loader .third {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 710;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-third;
  animation-timing-function: linear;
}

@keyframes site-loader-third {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 710;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 1420;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 1420;
    fill: var(--primaryColor);
  }

  to {
    stroke-dashoffset: 1420;
    fill: var(--primaryColor);
  }
}

.site-loader .fourth {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 430;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-fourth;
  animation-timing-function: linear;
}

@keyframes site-loader-fourth {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 430;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 860;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 860;
    fill: var(--primaryColor);
  }

  to {
    stroke-dashoffset: 860;
    fill: var(--primaryColor);
  }
}

.site-loader .first1 {
  stroke: #000000;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 150;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-first-1;
  animation-timing-function: linear;
}

@keyframes site-loader-first-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 150;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 300;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 300;
    fill: #000000;
  }

  to {
    stroke-dashoffset: 300;
    fill: #000000;
  }
}

.site-loader .second1 {
  stroke: #000000;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 570;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-second-1;
  animation-timing-function: linear;
}

@keyframes site-loader-second-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 570;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 1140;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 1140;
    fill: #000000;
  }

  to {
    stroke-dashoffset: 1140;
    fill: #000000;
  }
}

.site-loader .third1 {
  stroke: #8dc247;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 470;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-third-1;
  animation-timing-function: linear;
}

@keyframes site-loader-third-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  20% {
    stroke-dashoffset: 470;
    fill: transparent;
  }

  50% {
    stroke-dashoffset: 940;
    fill: transparent;
  }

  80% {
    stroke-dashoffset: 940;
    fill: #8dc247;
  }

  to {
    stroke-dashoffset: 940;
    fill: #8dc247;
  }
}

// site-loader-end

// form-css-start
.description-text {
  color: grey;
  font-size: 16px !important;
  line-height: 22px !important;
  margin-bottom: 13px;
  // font-family: 'Montserrat', sans-serif;
}

.field-wrapper {
  margin-bottom: 15px;
}

.form-label {
  color: var(--primaryColorDark);
  font-weight: 600;
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}

.form-control::placeholder,
textarea::placeholder {
  color: #b9b9c3 !important;
  opacity: 1;
}

input.form-control {
  padding: 8px 14px 8px 14px;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  font-size: 14px;
  height: 41.2px;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  appearance: none;
  // font-family: 'Montserrat', sans-serif;
  outline: none;

  &:focus {
    border: 1px solid var(--primaryColor);
    outline: none;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  }
}

.react-tel-input {
  input.form-control {
    // padding: 12px 14px 12px 48px;
    padding: 9px 14px 10px 48px;
    // border-radius: 10px;
    border-radius: 7px;
    font-size: 16px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    height: auto;
    line-height: 20px;
    width: 100% !important;

    &:focus {
      border: 1px solid var(--primaryColor);
    }
  }

  .flag-dropdown {
    border: none !important;
    border-right: 1px solid #e8e8e8 !important;
    background-color: #ffffff !important;
    top: 1px !important;
    bottom: unset !important;
    height: calc(100% - 2px);
    left: 1px;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    .selected-flag {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }

  .country-list {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
    background-color: #fff !important;
    border: 1px solid #e8e8e8;
    border-radius: 10px !important;
    margin: 5px 0px !important;

    li.country {
      padding: 12px 12px;

      &.highlight {
        background-color: #9cd256;
        color: #fff;

        .flag {
          border: 1px solid white;
        }

        .dial-code {
          color: #ffffff !important;
        }
      }
    }
  }
}

.submit-btn-wrapper {
  button {
    margin-top: 10px !important;
    width: 100% !important;
    padding: 12px 20px;
    border-color: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
    border: none;
    // font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0.358rem;
  }
}

.custom-fileupload {
  border: 2px dashed #a3db59;
  border-radius: 10px;
  background-color: rgba(163, 219, 89, 0.04);

  .inner-wrapper {
    padding: 15px 15px;
  }

  .outsite-CN {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      color: #6e6b7b;
    }

    h5 {
      font-family: inherit;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1em;
      margin-bottom: 7px;
      margin-top: 5px;
      text-align: center;
      color: #5e5873;
    }

    p.text-secondary {
      color: grey;
      font-size: 14px;
      text-align: center;
    }

    p {
      margin-bottom: 6px;
    }
  }

  .uploded-items {
    .file-details {
      width: calc(100% - 20px);
      padding-right: 10px;
      display: flex;
      align-items: center;
    }

    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--primaryColor);
      padding-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
        border-bottom: none;
        padding-bottom: 0px;
      }

      .file-preview {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .file-name-size {
        font-size: 16px;
        width: calc(100% - 30px);
        padding-left: 10px;
        white-space: pre;
        overflow: hidden;

        p {
          margin-bottom: 0px;
        }
      }
    }

    .remove-btn {
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .attachment-wrapper {
    margin-top: 20px;
    justify-content: center;

    .file__card {
      width: 190px;

      .inner-border-wrapper {
        background-color: #ffffff;

        .inner-wrapper {
          padding-top: 90%;
        }
      }
    }
  }
}

.invalid-feedback {
  color: red;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

// form-css-end
// loyallist-form-start
.loyallist-form {
  border-radius: 8px;
  border: 1px solid #eae8e1;
  background-color: #ffffff;
  width: 600px;
  max-width: calc(100% - 30px);
  margin: 0px auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 20px;

  .loader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .spinner-border {
      border: var(--bs-spinner-border-width) solid var(--primaryColor);
      border-right-color: transparent;
    }
  }

  .top-user-profile {
    padding: 15px 20px;
    // background-color: whitesmoke;
    border-radius: 12px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-img {
      width: 60px;
      height: 60px;
      display: block;
      flex-shrink: 0;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }

    .right-details {
      width: 100%;
      padding-left: 15px;

      &:empty {
        display: none;
      }

      .title {
        font-size: 22px;
        color: var(--primaryColor);
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 4px;
      }

      .text {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
        color: #000000;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    .right-details.logo__not {
      padding-left: 0px;

      .title,
      .text {
        text-align: center;
      }
    }
  }

  textarea.form-control {
    // padding: 11px 14px 13px 14px;
    // border-radius: 10px;
    padding: 8px 14px 8px 14px;
    border-radius: 7px;
    font-size: 16px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    font-size: 14px;
    height: 100px;
    width: 100%;
    outline: none;
    // font-family: 'Montserrat', sans-serif;

    &:focus {
      border: 1px solid var(--primaryColor);
    }
  }

  textarea.form-control[disabled] {
    background-color: #f9f9f9;
    color: #b1b1b1;
  }

  .loyallist-form-error-wrapper {
    .logo-wrapper {
      height: 60px;
      margin: 0px auto;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
      }
    }

    .company-name {
      text-align: center;
      margin-top: 10px;
    }

    .error-text {
      font-size: 16px;
      text-align: center;
      color: grey;
      font-weight: 400;
      margin-top: 14px;
    }
  }

  .thankYou-msg-wrapper {
    padding: 20px 0px;

    .icon-wrapper {
      width: 60px;
      height: 60px;
      object-fit: contain;
      object-position: center;
      margin: 0px auto;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      font-size: 28px;
      text-align: center;
    }

    .text {
      font-size: 16px;
      text-align: center;
      color: grey;
      margin-top: 10px;
    }
  }
}

// fancy (embed) form
.fancy-form {
  padding: 50px 15px;
  display: flex;
  justify-content: center;

  .loader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .spinner-border {
      border: var(--bs-spinner-border-width) solid var(--primaryColor);
      border-right-color: transparent;
    }
  }

  .inner-wrapper {
    padding: 20px 20px;
    width: 900px;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
  }

  .profile-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .profile-img {
      width: 100px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .right {
      width: calc(100% - 100px);
      padding-left: 15px;

      .company-name {
        font-size: 26px;
        font-weight: 700;
        color: #000000;
      }

      .form-name {
        font-size: 20px;
        font-weight: 700;
        color: grey;
      }
    }
  }

  .top-user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    // background-color: whitesmoke;
    border-radius: 12px;
    margin-bottom: 15px;

    .profile-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: block;
      flex-shrink: 0;

      &:empty {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .right-details {
      width: 100%;
      padding-left: 15px;

      &:empty {
        display: none;
      }
    }

    .title {
      font-size: 22px;
      color: var(--primaryColor);
      font-weight: 700;
      margin-bottom: 0px;
    }

    .text {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0px;
      margin-top: 4px;
    }

    .right-details.logo__not {
      padding-left: 0px;

      .title,
      .text {
        text-align: center;
      }
    }
  }

  .description-text {
    margin-top: 17px;

    .rdw-editor-wrapper {
      border: none;
      font-size: 16px;

      .rdw-editor-main {
        border: none !important;
      }
    }
  }

  .submit-btn-wrapper {
    padding-bottom: 10px;

    .btn {
      background-color: #000000 !important;
      color: yellow !important;
      border: 1px solid #000000 !important;
      width: 170px !important;

      &:hover {
        background-color: yellow !important;
        color: #000000 !important;
      }
    }
  }

  .form-label {
    color: #000000;
  }
}

// fancy (embed) form

@media only screen and (max-width: 575px) {
  .loyallist-form {
    .top-user-profile {
      padding: 14px 14px;

      .profile-img {
        margin-right: 12px;
      }

      .right-details {
        .company-name {
          font-size: 20px;
        }

        .form-name {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 374px) {
  .loyallist-form {
    .top-user-profile {
      flex-wrap: wrap;

      .profile-img {
        margin-bottom: 12px;
      }
    }
  }
}

/** dynamic form */
.dynamic-form-main-sec {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.dynamic-form-wrapper {
  padding: 0px 22px 0px 22px !important;
  position: relative;
  padding-top: var(--formElementSpacing) !important;
  letter-spacing: 0.2px;
  font-size: 16px;

  p {
    line-height: normal;
  }

  .loyallist-form-error-wrapper {
    .logo-wrapper {
      height: 60px;
      margin: 0px auto;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
      }
    }

    .company-name {
      text-align: center;
      margin-top: 10px;
    }

    .error-text {
      font-size: 16px;
      text-align: center;
      color: grey;
      font-weight: 400;
      margin-top: 14px;
    }
  }

  .form-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .inner-wrapper {
    position: relative;
    z-index: 2;
  }

  .top-user-profile {
    // padding: 15px 15px;
    // background-color: whitesmoke;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--formElementSpacing);

    .profile-img {
      width: 60px;
      height: 60px;
      display: block;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }

    .right-details {
      width: 100%;
      // padding-left: 15px;
      margin-top: 15px;
      text-align: center;

      .title {
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 0px;
      }

      .text {
        margin-bottom: 0px;
        margin-top: 4px;
        font-size: 1.1em;
        line-height: normal;
        font-weight: 600;
      }
    }

    .right-details.logo__not {
      padding-left: 0px;

      .title,
      .text {
        text-align: center;
      }
    }
  }

  .DraftEditor-editorContainer {
    border-left: none !important;
  }

  .rdw-editor-main {
    border: none !important;
    overflow: visible !important;
  }

  .rdw-editor-wrapper {
    border: none !important;
  }

  .card-text {
    line-height: normal;
    margin-bottom: var(--formElementSpacing) !important;
  }

  .auth-login-form {
    margin-top: 0px !important;
  }

  .form-field-wrapper {
    margin-bottom: var(--formElementSpacing);

    .text-danger {
      font-size: 0.857em !important;
    }
  }

  .form-label {
    font-size: 1em;
    font-weight: 600;
  }

  input.form-control {
    padding: 8px 14px 8px 14px;
    border-radius: var(--formElementRadius);
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    height: auto;
    font-size: 1em;
    color: #000000;
  }

  .react-tel-input {
    border-radius: var(--formElementRadius) !important;
    overflow: hidden;
  }

  input.form-control[type="tel"] {
    padding: 11px 14px 11px 48px;
    font-size: var(--formFontSize);
    font-family: var(--formFontFamily);
  }

  .custom-select__control {
    border-radius: var(--formElementRadius) !important;
  }

  textarea.form-control {
    padding: 11px 14px 13px 14px !important;
    border-radius: var(--formElementRadius);
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    font-size: 1em;
    color: #000000;
    line-height: normal !important;
  }

  .custom-select__control {
    .custom-select__placeholder {
      font-size: 1em;
    }

    .custom-select__value-container {
      padding: 9px 0px 9px 0px;
    }

    &.custom-select__control--is-focused {
      box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
      border: 1px solid #e8e8e8;
    }
  }

  .custom-fileupload {
    background-color: white;
    border-radius: var(--formElementRadius);

    .outsite-CN {
      .title {
        font-size: 1em;
        text-align: center;
      }

      .text-secondary {
        text-align: center;
        line-height: normal;
        font-size: 1em;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .thankYou-default-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 1.8em;
      margin-bottom: 5px;
      line-height: normal;
    }

    .text {
      font-size: 1em;
      line-height: normal;
    }
  }

  .go-back-btn-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-bottom: 20px;

    svg {
      margin-right: 2px;
    }

    .back-arrow {
      width: 30px;
      height: 30px;
      font-size: 0px;
      position: relative;
      transition: 0.5s;
      border-radius: 50%;
      cursor: pointer;

      .arrow-shape {
        position: absolute;
        top: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #000;
        left: 13px;
        border-left: 2px solid #000;
        transform: rotate(45deg);
      }
    }

    a {
      display: inline-flex;
      align-items: center;
    }
  }

  .submit-btn-wrapper {
    padding-bottom: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin-top: 0px !important;
    padding-top: var(--formElementSpacing);
    padding-bottom: var(--formElementSpacing);

    .btn {
      width: 100% !important;
      max-width: 100% !important;
      padding: 10px 22px 12px 22px !important;
      font-size: 1em;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-radius: var(--formElementRadius) !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .submit-wrapper-no-border {
    padding-bottom: var(--formElementSpacing);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .btn {
      // width:100% !important;
      max-width: 100% !important;
      padding: 10px 22px 12px 22px !important;
      font-size: 1em;
      margin-top: 5px;
      border-radius: var(--formElementRadius) !important;
      border: none;
      font-weight: 600;
    }
  }

  &.embed-form {
    .submit-btn-wrapper {
      padding-top: var(--formElementSpacing);
      padding-bottom: var(--formElementSpacing);
    }
  }
}